<template>


   <div>
       <v-container>
             <v-layout row wrap v-if="loading" style="min-height: 1000px">

                        <v-flex xs4></v-flex>
                        <v-flex xs4 style="text-align: center;margin-top:25px;position: relative;top: 100px;">

                            <v-progress-circular v-if="loading" indeterminate color="#276050" :size="120">
                            </v-progress-circular>

                        </v-flex>



                        <v-flex xs4></v-flex>






                    </v-layout>
       </v-container>

        <div class="zozo-main-wrapper" style="min-height:400px">
            <div id="main" class="main-section">
                
        <v-row row wrap style="    width: 100%;">
         
            <div class="col col-12">

                <v-carousel  v-if="!loading" :show-arrows="false" v-model="model" hide-delimiter-background>
                    <v-carousel-item v-for="item in  slider" :key="item">
                        <div class="v-card v-card--flat v-sheet v-sheet--tile theme--dark" dark="" style="height:100%">
                            <div class="v-responsive v-image grey lighten-2" style="height: 100%; width: 100%;">

                                <div class="v-image__image v-image__image--cover"
                                    style="background-image: url(&quot;https://abnakr.org/back_end/main.jpeg&quot;); background-position: center center;">
                                </div>


                                <v-row style="background-color: #00000036;" row wrap>
                                    <v-col class="d-none d-sm-flex" xs="1" cols="12" md="3" sm="3">
                                        <div class="d-none d-sm-flex"></div>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6" sm="6">

                                        <h1 class="web_name_sliderrr display-3 font-weight-light"
                                            style="color:#fff;font-size:35px;background-color: #03adef7a;"
                                            v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                            {{ $t("web_name") }}</h1>
                                        <!-- <div class="subheading text-uppercase pl-2 mb-4"> {{item.sub_title}} </div>
                                            <button type="button"
                                                class="v-btn v-btn--depressed theme--dark v-size--default primary"><span
                                                    class="v-btn__content"> Subscribe </span></button> -->
                                    </v-col>
                                    <v-col class="d-none d-sm-flex" xs="3" md="3" sm="3"></v-col>
                                </v-row>



                            </div>
                        </div>
                    </v-carousel-item>
                </v-carousel>

  <div v-else>
                <v-progress-circular  indeterminate color="#276050" :size="120">
                            </v-progress-circular>
            </div>

            </div>

          
        </v-row>

        <div class="rrr">

            <br>
            <br>
        </div>
        <div class="zozo-main-wrapper">
            <div class="container">
                <v-layout row wrap v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">

                    <v-flex xs12 md2 sm2>

                        <div class="wpb_wrapper">
                            <div
                                class="zozo-parallax-header margin-bottom-30 wpb_animate_when_almost_visible wpb_bottom-to-top wpb_start_animation animated">
                                <div class="parallax-header content-style-default">
                                    <h2 class="parallax-title text-left"
                                        style="font-size:48px;line-height:48px;margin:20px 0 0 0;"
                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                        {{ $t("about") }}</h2>
                                </div>
                            </div>
                        </div>

                    </v-flex>


                    <v-flex xs12 md10 sm10>

                        <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                    <blockquote
                                        v-bind:class="[{'border_linee_ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='border_linee_en'}]">
                                        <p v-if="aboutt"
                                            v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                            {{about_first[0].description}}</p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>

                    </v-flex>



                </v-layout>
            </div>
            <section
                class="vc_row wpb_row vc_row-fluid vc_custom_1490936387408 vc-zozo-section typo-default bg-style light-wrapper">
                <div class="zozo-vc-main-row-inner vc-normal-section">
                    <div class="container">
                        <div class="row">
                            <div class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div class="zozo-vc-row-inner vc-inner-row-section clearfix">
                                                <div v-for="about  in about_around" :key="about"
                                                    class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="zozo-feature-box feature-box-style style-default-box style-sep-no  clearfix"
                                                                id="feature-box-1">
                                                                <div class="grid-item">
                                                                    <div
                                                                        class="grid-box-inner grid-text-center grid-box-exlarge grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                        <div class="grid-icon-wrapper icon-hv-br shape-icon-circle"
                                                                            style="    background-color: #03adef;
    width: 100px;
    height: 100px;
    border: 3px;
    border-radius: 60px;
">
                                                                            <i v-bind:class="about.img_file" style="font-size: 48px;
    text-align: center;
    color: rgb(255, 255, 255);
    position: relative;
    top: 22px;" class="fa-2x"></i>
                                                                        </div>
                                                                        <h3 class="grid-title-below grid-title "
                                                                            style="font-family: 'Cairo' !important;">
                                                                            {{about.title}}</h3>
                                                                        <div class="grid-desc">
                                                                            <p>{{about.description}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-no  clearfix"
                                                                        id="feature-box-2">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-exlarge grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div
                                                                                    class="grid-icon-wrapper icon-hv-br shape-icon-circle">
                                                                                    <i
                                                                                        class="simple-icon icon-grid grid-icon zozo-icon icon-shape icon-circle icon-skin-default icon-bg pattern-1 icon-exlarge"></i>
                                                                                </div>
                                                                                <h3
                                                                                    class="grid-title-below grid-title ">
                                                                                    Vision</h3>
                                                                                <div class="grid-desc">
                                                                                    <p>Lorem ipsum dolor sit amet,
                                                                                        consectetur adipiscing elit.
                                                                                        Vestibulum nec odio ipsum.
                                                                                        Suspendisse cursus malesuada
                                                                                        facilisis.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-no  clearfix"
                                                                        id="feature-box-3">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-exlarge grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div
                                                                                    class="grid-icon-wrapper icon-hv-br shape-icon-circle">
                                                                                    <i
                                                                                        class="fas fa-th simple-icon icon-charitys grid-icon zozo-icon icon-shape icon-circle icon-skin-default icon-bg pattern-1 icon-exlarge"></i>
                                                                                </div>
                                                                                <h3
                                                                                    class="grid-title-below grid-title ">
                                                                                    Mission</h3>
                                                                                <div class="grid-desc">
                                                                                    <p>Lorem ipsum dolor sit amet,
                                                                                        consectetur adipiscing elit.
                                                                                        Vestibulum nec odio ipsum.
                                                                                        Suspendisse cursus malesuada
                                                                                        facilisis.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>


        <div class="container">
            <div class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                        <div class="zozo-parallax-header margin-bottom-20">
                            <router-link to="/blog" style="font-family: 'Cairo' !important;">
                                <div class="parallax-header content-style-default">
                                    <h2 class="parallax-title "
                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                        {{ $t("header.Blog") }}</h2>
                                </div>
                            </router-link>
                        </div>
                        <!-- //    outline: none;
    width: 227
px
; -->


                        <v-layout row wrap>
                            <v-flex xs12 md3 sm3 style="width:95%" v-for="item in art_cats" :key="item.id" id="post-145" :to="'/artByCat/'+item.id"
                                class="">
                                <router-link :to="'/artByCat/'+item.id"
                                    style="font-family: 'Cairo' !important;color: #181618 !important;">
                                    <div style="width:95%" class="post-inner-wrapper posts-inner-container clearfix">
                                        <div class="entry-meta-top-wrapper"></div>
                                        <div class="post-featured-image only-image">
                                            <div class="entry-thumbnail-wrapper">
                                                <div >
                                                    <a 
                                                        class="post-img" title="Educate Kids">


                                                        <div style="font-family: 'Cairo' !important;">
                                                            <img style="width:100%"
                                                                :src="Url+'/art_cat_img/thumb/'+item.img_file"
                                                                class="attachment-charity-blog-medium size-charity-blog-medium wp-post-image"
                                                                alt="" loading="lazy">
                                                        </div>



                                                    </a></div>
                                            </div>
                                        </div>
                                        <div class="posts-content-container" style="position: relative; background-color: #00000082; bottom: 76px;
                                        padding: 11px 0 0 !important">
                                            <div class="entry-header">
                                                <h2 class="entry-title"><a
                                                     
                                                        rel="bookmark" data-title="Educate Kids" title="Educate Kids"
                                                        style="
                                                       color:#fff;font-size:22px;  position: relative;
                                                       width: 100%;bottom: 19px;text-align: center !important;

                                                          
 
   

                                                        "
                                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                                        {{item.name}} </a></h2>
                                            </div>
                                            <BR></BR>
                                          
                                        </div>
                                    </div>
                                </router-link>
                            </v-flex>


                        </v-layout>

                        <!-- <v-layout row wrap>

                            <v-flex xs5></v-flex>
                            <v-flex xs2>
                                <v-btn to="/blog" small color="#12adef" style="
            position: relative;
            width:100px;
            color:#fff;
    float: right;
    bottom:19px;
    right: 19
px
;
        ">المزيد</v-btn>
                            </v-flex>

                            <v-flex xs5></v-flex>
                        </v-layout> -->


                        <!-- <VueSlickCarousel :rtl="true" :arrows="true" :dots="false" v-bind="settings"
                            class="lastNewsSlider" ref="lastNewsSlider">


                            <div style="width:95%" v-for="item in art" :key="item" id="post-145"
                                class="latest-posts-slider post-145 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-featured">
                                <router-link :to="{ name: 'art', params: { id:item.id }}"
                                    style="font-family: 'Cairo' !important;color: #181618 !important;">
                                    <div style="width:95%" class="post-inner-wrapper posts-inner-container clearfix">
                                        <div class="entry-meta-top-wrapper"></div>
                                        <div class="post-featured-image only-image">
                                            <div class="entry-thumbnail-wrapper">
                                                <div class="entry-thumbnail"><a
                                                        href="http://themes.zozothemes.com/charity/2017/03/23/educate-kids/"
                                                        class="post-img" title="Educate Kids"><img width="570"
                                                            height="370"
                                                            :src="Url+'/art_img/thumb/'+item.image.image_name"
                                                            class="attachment-charity-blog-medium size-charity-blog-medium wp-post-image"
                                                            alt="" loading="lazy"></a></div>
                                            </div>
                                        </div>
                                        <div class="posts-content-container">
                                            <div class="entry-header">
                                                <h2 class="entry-title"><a
                                                        href="http://themes.zozothemes.com/charity/2017/03/23/educate-kids/"
                                                        rel="bookmark" data-title="Educate Kids" title="Educate Kids"
                                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                                        {{item.articles_cats.name}}</a></h2>
                                            </div>
                                            <BR></BR>
                                            <div class="entry-summary">
                                                <p style="color: #181618 !important" class="xz"
                                                    v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                                    {{item.title}}
                                                </p>
                                            </div>
                                            <div class="entry-meta-wrapper">
                                                <div class="post-date">
                                                    <p class="latest-post-date"
                                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                                        <i class="far fa-clock"></i> {{item.created_at|formatMonn}} </p>
                                                </div>
                                                <div class="entry-meta"></div>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>


                        </VueSlickCarousel> -->





                    </div>
                </div>
            </div>


        </div>

        <!--about--->


        <section data-vc-parallax="1.5"
            data-vc-parallax-image="http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/testimonial-bg.jpg"
            class="vc_row wpb_row vc_row-fluid vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving vc-zozo-section typo-light bg-overlay-dark">
            <div class="zozo-vc-main-row-inner vc-normal-section">
                <div class="container">
                    <div class="vc_tta-panel vc_active" id="1490613796141-29e222fa-0d0b"
                        data-vc-content=".vc_tta-panel-body">
                        <div class="vc_tta-panel-heading">
                            <h4 class="vc_tta-panel-title"><a href="#1490613796141-29e222fa-0d0b" data-vc-accordion=""
                                    data-vc-container=".vc_tta-container"><span class="vc_tta-title-text"></span></a>
                            </h4>
                        </div>
                        <div class="vc_tta-panel-body" style="">
                            <div class="zozo-parallax-header margin-bottom-30">
                                <div class="parallax-header content-style-default">
                                    <h2 class="parallax-title text-center" style="font-family: 'Cairo' !important;">
                                        {{$t("org_goals") }}</h2>
                                </div>
                            </div>
                            <div class="vc_row wpb_row vc_inner vc_row-fluid"
                                v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                <div class="row"
                                    v-bind:class="[{'grid-text-right' : $i18n.locale=='ar' },{'grid-text-left':$i18n.locale=='en'}]">
                                    <div class=" vc_col-sm-12 vc_col-lg-4 vc_col-md-4 " v-for="about in about_goals"
                                        :key="about">
                                        <div class="vc_column-inner "
                                            v-bind:class="[{'grid-text-right' : $i18n.locale=='ar' },{'grid-text-left':$i18n.locale=='en'}]">
                                            <div class="wpb_wrapper">
                                                <div class="zozo-feature-box feature-box-style style-default-box style-sep-yes  clearfix"
                                                    id="feature-box-4">
                                                    <div class="grid-item"
                                                        v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                                        <div class="grid-box-inner  grid-box-normal grid-box-icon-none grid-shape-none"
                                                            v-bind:class="[{'grid-text-right' : $i18n.locale=='ar' },{'grid-text-left':$i18n.locale=='en'}]">
                                                            <div class="grid-icon-wrapper no-hover shape-icon-none">
                                                                <i v-bind:class="about.img_file"
                                                                    class="simple-icon icon-bulb grid-icon zozo-icon icon-none icon-skin-default icon-bg pattern-1 icon-normal"></i>
                                                            </div>
                                                            <div class="grid-content-wrapper"
                                                                style="    padding-right: 15px;">
                                                                <h4 class="grid-title "
                                                                    v-bind:class="[{'menu-item_ar' : $i18n.locale=='ar' },{'menu-item_en':$i18n.locale=='en'}]">
                                                                    {{about.title}}</h4>
                                                                <div class="grid-desc">
                                                                    <p>

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vc_parallax-inner" data-bottom-top="top: -50%;" data-top-bottom="top: 0%;"
                style="height: 150%; background-image: url(&quot;http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/testimonial-bg.jpg&quot;);">
            </div>
        </section>
        <section
            class="vc_row wpb_row vc_row-fluid vc_custom_1490770872950 vc-zozo-section typo-light bg-style bg-normal">
            <div class="zozo-vc-main-row-inner vc-normal-section">
                <div class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                    <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                            <section class="vc_cta3-container">
                                <div
                                    class="vc_general vc_cta3 vc_cta3-style-default vc_cta3-shape-square vc_cta3-align-center vc_cta3-color-classic vc_cta3-icon-size-md vc_cta3-actions-bottom">
                                    <div class="vc_cta3_content-container">
                                        <div class="vc_cta3-content">

                                            <header class="vc_cta3-content-header">
                                                <h2 style="  font-family: 'Cairo' !important;">

                                                    {{ $t("WE_NEED_YOUR_HELP") }}</h2>
                                            </header>
                                        </div>
                                        <div class="vc_cta3-actions">
                                            <div class=" vc_btn3-container vc_btn3-center "><a style="" class="givee"
                                                    data-title="" title=""> {{ $t("You_donate_via_bank_account") }}
                                                    866209
                                                    {{ $t("bank") }} </a></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <br>
        <br>
        <b></b>









        <div class="container">
            <!--contact as ------------------------------->
            <div class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                        <div class="">
                            <router-link to="/contact" style="font-family: 'Cairo' !important;">
                                <div class="parallax-header content-style-default">
                                    <h2 class="parallax-title "
                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                        {{ $t("contact") }}</h2>
                                </div>
                            </router-link>
                        </div>

                        <section
                            class="vc_row wpb_row vc_row-fluid vc_custom_1491215938045 vc-zozo-section typo-default">
                            <div class="zozo-vc-main-row-inner vc-normal-section">
                                <div class="container">
                                    <div class="row"
                                        v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                        <div class="wpb_column vc_main_column vc_column_container vc_col-sm-4 typo-default"
                                            v-bind:class="[{'con_men_ar' : $i18n.locale=='ar' },{'con_men_en':$i18n.locale=='en'}]">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div role="form" class="wpcf7" id="wpcf7-f803-p789-o1" lang="en-US"
                                                        dir="ltr">
                                                        <div class="screen-reader-response">
                                                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                            <ul></ul>
                                                        </div>
                                                        <form action="/charity/contact/#wpcf7-f803-p789-o1"
                                                            method="post" class="wpcf7-form init"
                                                            novalidate="novalidate" data-status="init">

                                                            <div style="display: none;"> <input type="hidden"
                                                                    name="_wpcf7" value="803"> <input type="hidden"
                                                                    name="_wpcf7_version" value="5.4.2"> <input
                                                                    type="hidden" name="_wpcf7_locale" value="en_US">
                                                                <input type="hidden" name="_wpcf7_unit_tag"
                                                                    value="wpcf7-f803-p789-o1">

                                                                <input type="hidden" name="_wpcf7_container_post"
                                                                    value="789"> <input type="hidden"
                                                                    v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                    name="_wpcf7_posted_data_hash" value=""> <input
                                                                    style="border: 1px solid;" type="hidden"
                                                                    name="_wpcf7_recaptcha_response"
                                                                    value="03AGdBq26jUCeCWu6pE_X-xZ-fD4uA9vvkDIFM-U2mcAwkKUJN78gGXtN0Mcr0yC_bLiSQ-DRqvYXdNfQ-kE8ZkLSVZVN07YocGYXMGkXiU7Btohwqn4y5lGSkjLEtlMDGhka-_06rfNeeZcDRjkC8IW_eOYFQyafeAzfXefBQpSS8v32wPJuawXxqbcKER6sC3ZvMoJhWOEym2JAddcCLrwn7CDMelKNv1m5IFmlI4paEIL4p--hsDm9N1uiEkV1Z8-og9-gcFnURsp6r6U_HLlaG0kjvHSK9fFaOoa50IztheLN7ge3EXFxtntIh-bryUXzFYF4DpKUUSwn4nAclhw6exkqnIxQLY3rh_nROi4v-kAySK2jCUvFVDnPZ5P_IrEXB6ugEiC9yJldO8qSJaUuxR1bA6SiX-wRmk8wHkxDv7SpSOya1Ij-mbIraCID6ZG7Yx923iKx6ntnESUHFrmfQzoC1dCllOA">
                                                            </div>
                                                            <p><span class="wpcf7-form-control-wrap your-name"><input
                                                                        type="text" name="your-name" value="" size="40"
                                                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        v-model="editedItem.name"
                                                                        v-validate="'required|email'" :rules="nameRules"
                                                                        style="border: 1px solid #dfdfdf;"
                                                                        :placeholder="$t('name')"></span></p>
                                                            <p> <span class="wpcf7-form-control-wrap your-email"><input
                                                                        type="email" name="your-email" value=""
                                                                        size="40"
                                                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                        aria-required="true" aria-invalid="false"
                                                                        style="border: 1px solid #dfdfdf;"
                                                                        :rules="emailRules" v-model="editedItem.email"
                                                                        :placeholder="$t('email')"></span></p>
                                                            <!-- <p> <span class="wpcf7-form-control-wrap tel-265"><input
                                                                        type="tel" name="tel-265" value="" size="40"
                                                                        style="border: 1px solid #dfdfdf;"
                                                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                                                                        aria-invalid="false"
                                                                        :placeholder="$t('phone')"></span></p> -->
                                                            <p> <span
                                                                    class="wpcf7-form-control-wrap your-message"><textarea
                                                                        name="your-message" cols="40" rows="10"
                                                                        style="border: 1px solid #dfdfdf;"
                                                                        class="wpcf7-form-control wpcf7-textarea"
                                                                        :rules="comRules" required
                                                                        v-model="editedItem.message"
                                                                        v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                        aria-invalid="false"
                                                                        :placeholder="$t('message')"></textarea></span>
                                                            </p>
                                                            <p>


                                                                <v-btn depressed color="green" @click="submit()">
                                                                    ارسال
                                                                </v-btn>
                                                                <span class="ajax-loader"></span>
                                                            </p> <input type="hidden"
                                                                v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                id="ct_checkjs_cf7_1f50893f80d6830d62765ffad7721742"
                                                                name="ct_checkjs_cf7" value="0">
                                                            <div class="wpcf7-response-output" aria-hidden="true"></div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="wpb_column vc_main_column vc_column_container vc_col-sm-8 typo-default">
                                            <v-container fluid="" style="background:white">

                                                <gmap-map id="googleMap" :center="center" :zoom="15"
                                                    style="width:100%;  height: 400px;" @click.native="setPlace"
                                                    :clickable="true" :draggable="true">
                                                    <gmap-marker :key="index" v-for="(m, index) in markers"
                                                        :position="m.position" @click="center=m.position">
                                                    </gmap-marker>
                                                </gmap-map>


                                            </v-container>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>







                    </div>
                </div>
            </div>
        </div>












        <!-- Gallery-->
        <section data-vc-parallax="1.5"
            data-vc-parallax-image="http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/childrens-bg.jpg"
            class="vc_row wpb_row vc_row-fluid vc_custom_1490770840274 vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving vc-zozo-section typo-light bg-overlay-dark">
            <div class="zozo-vc-main-row-inner vc-normal-section">
                <div class="container" style="max-width: 1408px !important">


                    <div class="row">
                        <div class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="zozo-parallax-header">
                                        <div class="parallax-header content-style-default">
                                            <h2 class="parallax-title" style="font-family: 'Cairo' !important;">
                                                {{ $t("header.Gallery") }}</h2>
                                        </div>
                                    </div>
                                    <div id="zozo-portfolio-wrapper-1"
                                        class="zozo-portfolio-grid-wrapper zozo-isotope-grid-system default item-has-content">
                                        <div class="zozo-isotope-filters clearfix">



                                            <ul
                                                class="zozo-smartmenu portfolio-tabs arrow-style-filter arrow-center text-center">
                                                <li class="smartmenu-filter">
                                                    <ul class="smart-sub-menu portfolio-sub-filter"
                                                        v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                                        <li><a data-filter="*" @click="xxx(44411);getAll()"
                                                                v-bind:class="[{'active' : active==44411 }]"><span>{{ $t("show_all") }}
                                                                </span><span class="sub-arrow"></span></a></li>

                                                        <li v-for="item  in cats" :key="item"><a
                                                                v-bind:class="[{'active' : active==item.id }]"
                                                                data-filter="grid-cat-4"
                                                                @click="xxx(item.id);getByCat(item.id)"
                                                                class=""><span>{{  $i18n.locale=='ar'?item.name:item.name_en}}</span></a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>

                                        </div>
                                        <div class="zozo-isotope-wrapper">
                                            <div id="zozo_portfolio_1"
                                                class="zozo-isotope-wrapper no-gutter"
                                             >


                                           <v-layout row wrap >
                     <v-flex xs12 sm4 md4 xl3  v-for="item in photo" :key="item" >
                           
                                                <div id="portfolio-120" 
                                                    class="portfolio-item isotope-post post-iso-w3 post-iso-h3 grid-cat-5  zozo-img-wrapper style-default"
                                                  
                                                    >

                                                    <div class="post-inside-wrapper margin-top-0 animate_when_almost_visible bottom-t-top start_animation"
                                                        data-delay="200">
                                                        <div class="portfolio-content  overlay-full overlay-center">
                                                            <div class="portfolio-image-overlay-wrapper">
                                                                <a :href="Url+'/art_img/'+item.img_file"  :data-caption="item.title"
                                                                    :data-fancybox="item.id">



                                                                    <div>

                                                                        <v-img class="img-responsive"
                                                                        
                                                                                 :src="Url+'/art_img/thumb_new/'+item.img_file"
                                                                 :lazy-src="Url+'/art_img/small_thumb/'+item.img_file"
                                                                            
                                                                            
                                                                      
                                                                            alt="Become Volunteer" />
                                                                    </div>


                                                                </a>
                                                            </div>
                                                            <div class="portfolio-overlay zoomin"></div>
                                                            <div class="portfolio-mask overlay-mask position-center">
                                                                <!-- <div class="portfolio-cat"><a title="children"
                                                                        :href="Url+'/art_img/'+item.img_file" :data-caption="item.title"
                                                                        :data-fancybox="item.id">{{item.photo_gallery_cats.name}}</a>
                                                                </div> -->
                                                                <div class="portfolio-title">
                                                                    <h4><a title="Become Volunteer"
                                                                            :href="Url+'/art_img/'+item.img_file" :data-caption="item.title"
                                                                            :data-fancybox="item.id">
                                                                            {{item.photo_gallery_cats.name}}
                                                                        </a></h4>
                                                                </div>
                                                                <ul class="overlay-buttons fade-down">
                                                                    <li><a :href="Url+'/art_img/'+item.img_file"
                                                                           :data-fancybox="item"  :data-caption="item.title"
                                                                            title="Become Volunteer"><i
                                                                                class="fas fa-search-plus"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                     </v-flex>
                </v-layout>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vc_parallax-inner" data-bottom-top="top: -50%;" data-top-bottom="top: 0%;"
                style="height: 150%; background-image: url(&quot;http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/childrens-bg.jpg&quot;);">
            </div>
        </section>
    </div>
     </div>
                    </div>
</template>

<style>
    .givee {
        display: inline-block;
        font-family: 'Cairo' !important;
        background-color: #feb41f !important;
        padding: 18px;
        color: #000;
        font-weight: bold;
        font-size: 19px;
        position: relative;
        top: 15px
    }

    .row {
        margin: 0px !important;
    }


    .border_linee_ar {
        border-right: 5px solid #eee;
        border-left: none !important
    }

    .border_linee_en {
        border-right: 5px solid #eee;


    }

    .post-inner-wrapper {
        width: 95% !important;
    }

    .xz {
        color: #181618 !important
    }


    @media only screen and (max-width: 768px) {

        .givee {
            display: inline-grid !important;
            font-size: 12px !important;
        }

        .rrr {
            display: none;
        }

        #zozo_portfolio_1 {
            position: initial !important;
        }

        .portfolio-item {
            width: auto !important;
        }

        .web_name_sliderrr {
            position: relative;
            top: 0 !important;
            font-size: 30px;
        }

    }

    .v-window__prev {

        left: 95%;
    }

    .v-responsive__sizer {
        background-color: #00000040;
    }

    .v-responsive__content {
        background-color: #00000040;
    }

    .web_name_sliderrr {
        position: relative;
        top: 29%;
        font-size: 35px;
    }
</style>

<script>
    //import VueSlickCarousel from 'vue-slick-carousel'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    // optional style for arrows & dots

    import Axios from "axios";
    import {
        EventBus
    } from '../../components/core/event-bus.js';


    export default {
        components: {

            //   VueSlickCarousel
        },
        data: () => ({
            about_first: [],
            dialog:false,
            aboutt:false,
            slider: [{
                title: 'مؤسسة ابناء كربلاء للخير والرحمة',
                sub_title: 'مؤسسة ابناء كربلاء للخير والرحمة',

            }],

            center: {
                lat: 32.5792815,
                lng: 44.0337525
            },
            markers: [],
            places: [],
            model: 0,
            lat: 32.5792815,
            lng: 44.0337525,
            currentPlace: null,


            about_around: [],
            about_goals: [],


            editedItem: {},
            validate: false,
            nameRules: [
                (v) => !!v || 'يجب ادخال الاسم',

            ],
            comRules: [
                (v) => !!v || 'يجب ادخال الرساله',

            ],
            loading: true,
            emailRules: [


                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'الايميل غير صحيح'
            ],


            cats: [],
            photo: [],
            leftx: 0,
            left_old: 353,
            active: 44411,
            art_cats: [],
            x: 0,

            art: [],

            settings: {
                "dots": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 4,
                "initialSlide": 0,
                "responsive": [{
                        "breakpoint": 1024,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 3,
                            "infinite": true,
                            "dots": true
                        }
                    },
                    {
                        "breakpoint": 600,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 2,
                            "initialSlide": 2
                        }
                    },
                    {
                        "breakpoint": 480,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 2
                        }
                    }
                ]
            },
            team: [{
                name_ar: " الدكتور فائز اموري الاسدي",
                name_en: " Dr. Fayez Emory Al-Asadi",
                img: "Fayez.jpg",
                position_ar: " رئيس المؤسسة رئيس مجلس الادارة",
                position_en: "Chairman of the Foundation Chairman of the Board of Directors",
                Desc_ar: "طبيب ممارس عام العمر ٥٣ عام يسكن في كربلاء لدية ٦ اطفال يعمل في مستشفى الحسيني العام في قسم التدريب والتطوير الصحي يترأس الجمعية الخيرية منذ تاسيسها في عام ٢٠٠٣ لكن سجلت رسميا في عام ٢٠٠٦ بدأ العمل بها بامكانيات بسيطة نجمع اموال المؤسسة من التبرع من رواتبنا الشهرية لدينا في المؤسسة حوالي ٢٠٠ طبيب بمختلف الاختصاصات ويكون عملنا تطوعي ولاننتمي لاي جهة سياسية او دينية اهدافنا خدمة الانسان وخصوصا الفقراء والايتام والمعوزين والارامل والمعوقين",
                Desc_en: "A general practitioner, 53 years old, lives in Karbala, has 6 children, works in Al-Husseini General Hospital in the Health Training and Development Department. He has headed the Charitable Society since its founding in 2003, but it was officially registered in 2006. It started working with simple means. We collect the institution’s money from the donation of our monthly salaries. The institution has about 200 doctors in various specialties, and our work is voluntary and does not belong to any political or religious party. Our goals are to serve the human being, especially the poor, orphans, the destitute, widows and the disabled."
            }]
        }),
        methods: {

            getArtCat() {
                this.loading = true;
                Axios.get("articlesCats/GetByLang/65", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.art_cats = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            getAll() {
                /// this.loading = true;
                Axios.get("/photosGallery/getlimit/18", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        ///   this.loading = false;
                        //    alert('there');
     //                   console.log('there');
                        this.photo = res.data.data;
                        for (var i = 0; i < this.photo.length; i++) {






                            if (i == 0) {
                                this.leftx = 0;
                                this.photo[i].width = 0;

                            } else {
                                this.photo[i].width = 0;
                                this.leftx = this.leftx + 353;
                                if (i == 4 || i == 8 || i == 12 || i == 14 || i == 16 || i == 20 || i == 24 || i ==
                                    28 || i == 32 || i == 30 || i == 33 || i == 36 || i == 39 || i == 42) {
                                    this.leftx = 0;
                                }
                                this.photo[i].width = this.leftx;
                            }


                            if (i < 4) {

                                this.top = 0;

                                this.photo[i].top = this.top;

                            } else if (i < 8) {

                                //         this.photo[i].width=0;
                                this.top = 200;

                                this.photo[i].top = this.top;
                            } else if (i < 9) {

                                //       this.photo[i].width=0; 
                                this.top = 400;

                                this.photo[i].top = this.top;
                            } else if (i < 12) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 15) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 18) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 21) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 24) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            }







                        }



                    })
                    .catch(() => {
                        this.loading = false;
                    });

            },
            getByCat(item) {
                this.loading = true;
                Axios.get("/photosGallery/GetByCat/" + item, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.photo = res.data.data;
                        for (var i = 0; i < this.photo.length; i++) {
                            if (i == 0) {
                                this.leftx = 0;
                                this.photo[i].width = 0;

                            } else {
                                this.photo[i].width = 0;
                                this.leftx = this.leftx + 353;
                                if (i == 4 || i == 8 || i == 12 || i == 14 || i == 16 || i == 20 || i == 24 || i ==
                                    28 || i == 32 || i == 30 || i == 33 || i == 36 || i == 39 || i == 42) {
                                    this.leftx = 0;
                                }
                                this.photo[i].width = this.leftx;
                            }

                            if (i < 3) {

                                this.top = 0;

                                this.photo[i].top = this.top;

                            } else if (i < 6) {

                                //         this.photo[i].width=0;
                                this.top = 300;

                                this.photo[i].top = this.top;
                            } else if (i < 9) {

                                //       this.photo[i].width=0; 
                                this.top = 600;

                                this.photo[i].top = this.top;
                            } else if (i < 12) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 15) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 18) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 21) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            } else if (i < 24) {


                                this.photo[i].width = 0;

                                this.photo[i].top = this.top;
                            }


                        }


                    })
                    .catch(() => {
                        this.loading = false;
                    });

            },
            xxx(item) {

                this.active = item

            },
            getCats() {

                Axios.get("/photosGalleryCats", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.cats = res.data.data;
                        // console.log(res.data);
                    })
                    .catch(() => {
                        this.loading = false;
                    });

            },
            submit() {

                // if (this.$refs.form.validate()) {


                Axios.post('/messsages', {

                    name: this.editedItem.name,
                    //  phone: this.editedItem.phone,
                    email: this.editedItem.email,
                    message: this.editedItem.message,


                }).then(() => {
                    this.editedItem = {}
                    this.dialog = false;
                    this.$swal.fire({
                        title: "تم الارسال",
                        text: "",
                        icon: "success",
                        confirmButtonText: "اغلاق",
                    });

                    this.close();

                }).catch(() => {


                });


                // }


            },
            getArt() {
                this.loading = true;
                Axios.get("/articles/GetByLang/4", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.art = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            getByCatIdFirst(id) {
                this.loading = true;
                Axios.get("/aboutAs/getByCatId/" + id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.aboutt=true;
                        this.about_first = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            getByCatIdSecond(id) {
                this.loading = true;
                Axios.get("/aboutAs/getByCatId/" + id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.about_around = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            getByCatIdThread(id) {
                this.loading = true;
                Axios.get("/aboutAs/getByCatId/" + id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.about_goals = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            //getByCatIdSecond
            changeLang() {

                if (this.$i18n.locale == 'ar') {
                    this.team = [{
                            name: " الدكتور فائز اموري الاسدي",
                            img: "Fayez.jpg",
                            position: " رئيس المؤسسة رئيس مجلس الادارة",
                            Desc: "طبيب ممارس عام العمر ٥٣ عام يسكن في كربلاء لدية ٦ اطفال يعمل في مستشفى الحسيني العام في قسم التدريب والتطوير الصحي يترأس الجمعية الخيرية منذ تاسيسها في عام ٢٠٠٣ لكن سجلت رسميا في عام ٢٠٠٦ ",
                        },

                        {
                            name: " الاستاذ ابراهيم اسماعيل ابراهيم",
                            img: "lbrahim.jpg",
                            position: " المدير المفوض والمسئول المالي ",
                            Desc: " المدير المفوض والمسئول المالي وهو عصب ولولب الموسسة لانه متفرغ تماما لاعمال المؤسسة والكشف على الحالات المستحقة ومتابعة الفقراء وبناء البيوت  ومتابعة العمليات قبل دخولهم وبعد الخروج وتوفير العلاجات للمرضى والاوكسجين لمن يحتاج ",
                        },
                        {
                            name: "الدكتور احمد جعفر القرة غولي",
                            img: "ali.jpg",
                            position: "عضو مجلس الاداره",
                            Desc: "مواليد ١٩٦٩ لديه ٣ اولاد يعيش في كربلاء طبيب اختصاص نفسية وعصبية البورد العربي يعمل في مستشفى الحسيني التعليمي يرأس الكثير من اللجان الخاصة الطبية لديه الكثير من البحوث في امراض علم النفس والكأبة والذهان يرأس اللجنة الاعلامية واللجنة الخاصة بالام والطفل في المؤسسة",
                        },
                        {
                            name: " الدكتور مهند عبد الامير",
                            img: "muhend.jpg.jpg",
                            position: " مسوؤل اللجنة الاجتماعية والخدمية",
                            Desc: "  دكتوراه في الطب الباطني البورد العربي لديه ٣ اولاد يسكن كربلاء يعمل في مستشفى الحسيني عمل عدة اشهر في ردهة فايروس كورونا منصبه في المؤسسة مسوؤل اللجنة الاجتماعية والخدمية وحلقة الوصل بيننا وبين ابطال الجيش العراقي ومتابعة مرضى وجرحى الجيش ويترأس لجنة الاشراف على العيادات المجانية  لخدمة المرضى وعوائل الايتام والمساكين والفقراء",
                        }

                    ]
                } else {
                    this.team = [{
                            name: " Dr. Fayez Emory Al-Asadi",
                            img: "Fayez.jpg",
                            position: "Chairman of the Foundation Chairman of the Board of Directors",
                            Desc: "A general practitioner, age 53, lives in Karbala, has 6 children, works at Al-Husseini General Hospital in the Health Training and Development Department, and has headed the Charitable Society since its founding in 2003, but it was officially registered in",
                        },
                        {
                            name: " Professor Ibrahim Ismail Ibrahim",
                            img: "lbrahim.jpg",
                            position: " Managing Director and Financial Officer ",
                            Desc: "The Foundation is the authorized director and the financial official, who is the nerve and coil of the institution because he is fully devoted to the work of the Foundation, examining the outstanding cases, following up on the poor, building houses, following up on operations before entering and after leaving, providing treatments for patients and oxygen for those in need. ",
                        },
                        {
                            name: "Dr. Ahmed Jaafar Al-Qaraghuli",
                            img: "ali.jpg",
                            position: "Member of the Board of Directors",
                            Desc: "Born in 1969, has 3 children, lives in Karbala, a doctor specializing in psychiatry and neurology, the Arab Board, works in Al-Husseini Teaching Hospital, heads many special medical committees, has a lot of research in diseases of psychology, depression and psychosis,",
                        },
                        {
                            name: "Dr. Muhannad Abdul Amir",
                            img: "muhend.jpg",
                            position: " Responsible for the Social and Service Committee",
                            Desc: "College of Medicine, Doctor of Internal Medicine, Arab Board, He has 3 children, He lives in Karbala, He works in Al-Husseini Hospital, He worked for several months in the Corona virus hall, His position in the institution is responsible for the Social and Service Committee and the link between us and the heroes of the Iraqi army, following up on the patients and wounded of the army, and chairs the Supervisory Committee On free clinics to serve patients, families of orphans, the needy and the poor",
                        }

                    ]

                }




            },
        },
        mounted() {

            const marker = {
                lat: parseFloat(this.lat),
                lng: parseFloat(this.lng)
            };

            this.markers.push({
                position: marker
            });
            this.places.push(this.position);
            this.center = marker;
            this.currentPlace = null;


            // this.lat = this.getlocationposition.coords.latitude;
            // this.lng = this.position.coords.longitude;

        },
        created() {
            EventBus.$on("changeLangs", (from) => {
                from
                this.getByCatIdFirst(1)
                this.getByCatIdThread(2)
                this.getByCatIdSecond(3)
                this.getAll()
                this.getArt()
                this.getByCat()
                this.getArtCat();
                // this.getCats()


            });
            this.getByCatIdFirst(1)
            this.getByCatIdThread(2)
            this.getByCatIdSecond(3)
            this.getAll()
            this.getArt()
            this.getArtCat();
            this.getCats()
            //    this.getByCat()

        },

    }
</script>